import React, { lazy, Suspense, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import Popup from 'reactjs-popup';
import buildPresets from './data/eng/buildPresets.js'
import { useForm, SubmitHandler } from "react-hook-form"

const CrownPrince = lazy(() => import('./CrownPrince'));
const AoBai = lazy(() => import('./AoBai'));
const QingYan = lazy(() => import('./QingYan'));
const LeiLuo = lazy(() => import('./LeiLuo'));
const Tao = lazy(() => import('./Tao'));
const QianSui = lazy(() => import('./QianSui'));
const XingZhe = lazy(() => import('./XingZhe'));
const Li = lazy(() => import('./Li'));
const ZiXiao = lazy(() => import('./ZiXiao'));
const Nona = lazy(() => import('./Nona'));
const HomePage = lazy(() => import('./home'));

const publicUrl = process.env.PUBLIC_URL;

// Set the og:image meta tag with the actual image URL
const ogImage = document.querySelector('meta[property="og:image"]');
if (ogImage) {
    ogImage.setAttribute('content', `${publicUrl}/heroselectors/OGAoBaiIcon2.png`);
}


const heroImages = {
    'CrownPrince': 'CrownPrinceSelector.png',
    'AoBai': 'AoBaiSelector.png',
    'QingYan': 'QingYanSelector.png',
    'LeiLuo': 'LeiLuoSelector.png',
    'Tao': 'TaoSelector.png',
    'QianSui': 'QianSuiSelector.png',
    'XingZhe': 'XingZheSelector.png',
    'Li': 'LiSelector.png',
    'ZiXiao': 'ZiXiaoSelector.png',
    'Nona': 'NonaSelector.png',
};

function HeroSelectorButton({ hero }) {
    const location = useLocation();
    const isActive = location.pathname === `/${hero}`;
    

    const buttonStyle = {
        display: 'inline-block',
        height: 'auto',
        width: '120px',
        textAlign: 'center',
        lineHeight: '60px',
        color: 'white',
        fontSize: '0px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        textDecoration: 'none',
        marginBottom: '5px',
        marginRight: '10px',
        background: `url(${process.env.PUBLIC_URL}/heroselectors/${heroImages[hero]})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        filter: isActive ? 'none' : 'grayscale(100%)', 
        borderRadius: '5px',
    };

    return (

        <Link to={`/${hero}`} style={buttonStyle}>
            {hero}
        </Link>
    );
}



function App() {
    const location = useLocation();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
        document.body.classList.toggle('body-no-scroll', !isPopupOpen);
    };
  
    return (
       
            <div style={{ position: 'relative', maxHeight: '100vh', maxWidth: '300vh' }}>
                {/* Navigation bar */}
               
            <nav style={{ display: location.pathname === '/' ? 'none' : 'flex', justifyContent: 'center', maxWidth: '100%', overflowX: 'auto', overflowY: 'hidden', paddingTop: '5px', paddingBottom: '5px', backgroundColor: '#0000007a', borderBottom: '2px solid #ffd70082'  }}>
                    <ScrollContainer horizontal={true}>
                        <ul className="heroSelectorUl" style={{ listStyle: 'none', margin: 0, padding: 0, display: 'flex', alignItems: 'center' }}>
                        <li>
                            <HeroSelectorButton hero="AoBai" />
                        </li>
                        <li>
                            <HeroSelectorButton hero="CrownPrince" />
                        </li>
                        <li>
                            <HeroSelectorButton hero="QingYan" />
                        </li>
                        <li>
                            <HeroSelectorButton hero="LeiLuo" />
                        </li>
                        <li>
                            <HeroSelectorButton hero="Tao" />
                        </li>
                        <li>
                            <HeroSelectorButton hero="QianSui" />
                        </li>
                        <li>
                            <HeroSelectorButton hero="XingZhe" />
                        </li>
                        <li>
                            <HeroSelectorButton hero="Li" />
                        </li>
                        <li>
                            <HeroSelectorButton hero="ZiXiao" />
                        </li>
                        <li>
                            <HeroSelectorButton hero="Nona" />
                        </li>
                        </ul>
                        
                    </ScrollContainer>
                <div className="BuildsMenu">
                       
                                <Popup
                        trigger={<div className="BuildsButton">BUILDS</div>}
                                    position="right top"
                                    closeOnDocumentClick
                                    arrow={false}
                                    modal
                                    open={isPopupOpen} // Pass the open state to the Popup component
                                    onOpen={togglePopup} // Call the togglePopup function on open
                                    onClose={togglePopup} // Call the togglePopup function on close
                    >
                        <div className='popup-inner'>
                            <div class="weaponNameHeader">Builds</div>
                            <div className="builds-popup1">
                                {(() => {
                                    const filteredBuilds = buildPresets.reduce((filtered, hero) => {
                                        const readyBuilds = hero.builds.filter((build) => build.status === 'Ready');
                                        if (readyBuilds.length > 0) {
                                            filtered.push({
                                                heroName: hero.name,
                                                builds: readyBuilds,
                                            });
                                        }
                                        return filtered;
                                    }, []);

                                    return filteredBuilds.map((heroData, index) => (
                                        <React.Fragment key={index}>
                                            <div className="hero-header">
                                                <h4 className='HeroTitle1'>{heroData.heroName}</h4>
                                            </div>
                                            <div className="builds-popup">
                                                {heroData.builds.map((preset, presetIndex) => (
                                                    <div className="builds-option" key={presetIndex} onClick={() => { window.open(`${preset.page}?${preset.parameters}`); }}>
                                                        <div className="toolTipName">{preset.name}</div>
                                                        <img src={buildPresets.find(hero => hero.name === heroData.heroName)?.icon} alt={heroData.heroName} className="hero-icon" />
                                                        <div className='buildPopupDescription'><div className='toolTipName2'>Difficulty: </div> {preset.difficulty}</div>
                                                        <div className='buildPopupDescription'><div className='toolTipName2'>Author: </div> {preset.author}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        </React.Fragment>
                                    ));
                                })()}
                            </div>
                            <div className='popup-footer'></div>
                        </div>
                                </Popup>
                            
                    </div>
                </nav>
               
                <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/aobai" element={<AoBai />} />
                    <Route path="/CrownPrince" element={<CrownPrince />} />
                    <Route path="/qingyan" element={<QingYan />} />
                    <Route path="/leiluo" element={<LeiLuo />} />
                    <Route path="/tao" element={<Tao />} />
                    <Route path="/qiansui" element={<QianSui />} />
                    <Route path="/xingzhe" element={<XingZhe />} />
                    <Route path="/li" element={<Li />} />
                    <Route path="/zixiao" element={<ZiXiao />} />
                    <Route path="/nona" element={<Nona />} />
                    </Routes>
                </Suspense>
                {/* Footer */}
            <footer className='footerblock'>
                    {/*<ul style={{ listStyle: 'none', padding: 0 }}>*/}
                    {/*    <li style={{ display: 'inline-block', marginRight: '10px' }}>*/}
                    {/*        <Link style={{ color: 'gray' }} to="/">Link 1</Link>*/}
                    {/*    </li>*/}
                    {/*    <li style={{ display: 'inline-block', marginRight: '10px' }}>*/}
                    {/*        <Link style={{ color: 'gray' }} to="/">Link 2</Link>*/}
                    {/*    </li>*/}
                    {/*    <li style={{ display: 'inline-block' }}>*/}
                    {/*        <Link style={{ color: 'gray' }} to="/">Link 3</Link>*/}
                    {/*    </li>*/}
                    {/*</ul>*/}
                    <p style={{ marginTop: '10px', fontSize: '14px', color: 'gray' }}>
                    Builder patch - <Link style={{ color: 'gray' }} to="#" onClick={(e) => { e.preventDefault(); window.open('https://store.steampowered.com/news/app/1217060/view/3731854112016220841', '_blank'); }}>
                            Update - 10/21/2023
                        </Link>
                    </p>
                <p style={{ marginTop: '10px', fontSize: '14px', color: 'gray' }}>
                    2023 Created by Zohan. <a style={{ color: 'gray' }} href='https://discordapp.com/users/293704413795844096'>My Discord</a>. This site is not affiliated with DUOYI.
                    </p>
                    <p style={{ marginTop: '10px', paddingBottom: '10px', fontSize: '14px', color: 'gray' }}>
                        All rights for game assets reserved &#169;DUOYI (HONGKONG) INTERACTIVE ENTERTAINMENT LIMITED.
                    </p>
                </footer>
            </div>
       
    );
}

export default App;
