import Crown_Prince_Icon from '../../icons/heroicons/Crown_Prince_Icon.png'
import Lei_Luo_Icon from '../../icons/heroicons/Lei_Luo_Icon.png'
import Li_Icon from '../../icons/heroicons/Li_Icon.png'
import Nona_Icon from '../../icons/heroicons/Nona_Icon.png'
import Qian_Sui_Icon from '../../icons/heroicons/Qian_Sui_Icon.png'
import Qing_Yan from '../../icons/heroicons/Qing_Yan.png'
import Tao_Icon from '../../icons/heroicons/Tao_Icon.png'
import Xing_Zhe_Icon from '../../icons/heroicons/Xing_Zhe_Icon.png'
import Zi_Xiao_Icon from '../../icons/heroicons/Zi_Xiao_Icon.png'
import AoBaiIcon from '../../icons/heroicons/Ao_Bai_Icon.png'
import Spore from  '../../icons/heroicons/brainless-man-meme-hd-png-download.png'

const buildPresets = [
    {
   name: 'General Builds',
   icon: Spore,
   builds: [
                    {
                       
                       page: "/Tao",
           parameters: "bl=41&sc=&sk=003030003003000000&w1l=4&w2l=20&w=53%2C27&g=174&g2=174&i2=36%2C20%2C11%2C123&i=16%2C47%2C53%2C6",
                      'name': 'Spore',
                      'difficulty': 'Any',
                      'status': 'Ready',
                      'author': 'Someone'
                    },
                       
                    ],
            },
   {
   name: 'Ao Bai',
   icon: AoBaiIcon,
   builds: [
                    {
                       
                       page: "/AoBai",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                      'status': 'Ready'
                    },
                       { 
                        page: "/AoBai",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Strive to Survive',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                      { 
                        page: "/AoBai",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Dual-Wield',
                      'difficulty': 'Any',
                    },
                       { 
                        page: "/AoBai",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                       'name': '"Infinite" Dual-Wield',
                      'difficulty': 'Any',
                    },
                      { 
                        page: "/AoBai",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Explosive Weapon',
                      'difficulty': 'Any',
                    },
                    ],
            },
            {
   name: 'Crown Prince',
   icon: Crown_Prince_Icon,
   builds: [
                    {
                       
                       page: "/CrownPrince",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                       { 
                        page: "/CrownPrince",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Strive to Survive',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                      { 
                        page: "/CrownPrince",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                       { 
                        page: "/CrownPrince",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                       'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                      { 
                        page: "/CrownPrince",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                    ],
            },
             {
   name: 'Qing Yan',
   icon: Qing_Yan,
   builds: [
                    {
                       
                       page: "/CrownPrince",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                       { 
                        page: "/CrownPrince",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Strive to Survive',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                      { 
                        page: "/CrownPrince",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                       { 
                        page: "/CrownPrince",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                       'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                      { 
                        page: "/CrownPrince",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                    ],
            },
                {
   name: 'Lei Luo',
   icon: Lei_Luo_Icon,
   builds: [
                    {
                       
                       page: "/LeiLuo",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                       { 
                        page: "/LeiLuo",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Strive to Survive',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                      { 
                        page: "/LeiLuo",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                       { 
                        page: "/LeiLuo",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                       'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                      { 
                        page: "/LeiLuo",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                    ],
            },
              {
   name: 'Tao',
   icon: Tao_Icon,
   builds: [
                    {
                       
                       page: "/Tao",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                       { 
                        page: "/Tao",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Strive to Survive',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                      { 
                        page: "/Tao",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                       { 
                        page: "/Tao",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                       'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                      { 
                        page: "/Tao",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                    ],
            },
            {
   name: 'Qian Sui',
   icon: Qian_Sui_Icon,
   builds: [
                    {
                       
                       page: "/QianSui",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                       { 
                        page: "/QianSui",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Strive to Survive',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                      { 
                        page: "/QianSui",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                       { 
                        page: "/QianSui",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                       'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                      { 
                        page: "/QianSui",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                    ],
            },
             {
   name: 'Xing Zhe',
   icon: Xing_Zhe_Icon,
   builds: [
                    {
                       
                       page: "/XingZhe",
                       parameters: "bl=17%2C22%2C48&sc=1%2C5%2C16%2C47%2C60%2C64%2C106%2C108%2C114%2C118%2C124%2C127%2C130&sk=003001000003003303&w=44%2C54&g=172&g2=172&i=58%2C24%2C16%2C38&i2=4%2C53%2C16%2C13&w1l=20&w2l=5",
                      'name': 'Soul Strike 1',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                      'status': 'Ready'
                    },
                       { 
                        page: "/XingZhe",
                       parameters: "bl=50%2C22%2C17&sc=1%2C5%2C6%2C16%2C33%2C41%2C45%2C43%2C60%2C61%2C90%2C4%2C52%2C117%2C23%2C30%2C17%2C143%2C127%2C124%2C130%2C142&sk=000000000000133003&w=14%2C54&lw=0&w1l=20&w2l=5&g=172&i=85%2C8%2C47%2C19&g2=172&i2=46%2C57%2C22%2C16",
                      'name': 'Pew Pew 1',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                      'status': 'Ready'
                    },
                      { 
                        page: "/XingZhe",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                       { 
                        page: "/XingZhe",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                       'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                      { 
                        page: "/XingZhe",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                    ],
            },
             {
   name: 'Li',
   icon: Li_Icon,
   builds: [
                    {
                       
                       page: "/Li",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                       { 
                        page: "/Li",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Strive to Survive',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                      { 
                        page: "/Li",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                       { 
                        page: "/Li",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                       'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                      { 
                        page: "/Li",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                    ],
            },
              {
   name: 'Zi Xiao',
   icon: Zi_Xiao_Icon,
   builds: [
                    {
                       
                       page: "/ZiXiao",
                       parameters: "bl=58%2C6%2C8&sc=32%2C36%2C64%2C77%2C82%2C86%2C95%2C108%2C109%2C113%2C114%2C124%2C126%2C127%2C135%2C138%2C142%2C148%2C151&sk=010000000133333100&w1l=22&w2l=0&w=27%2C41&g=175&g2=175&i=10%2C16%2C8%2C60",
                      'name': 'Weapon Build 1',
                      'difficulty': 'Reincarnation',
                      'author': 'Yaroslohom',
                      'status': 'Ready'
                    },
                       { 
                        page: "/ZiXiao",
                       parameters: "bl=56%2C19%2C17&sc=1%2C22%2C31%2C49%2C50%2C58%2C59%2C66%2C82%2C87%2C109%2C110%2C113%2C115%2C130%2C147&g=175&g2=175&sk=010000333133000000&w=29%2C41&i2=14%2C15%2C41%2C71&i=73%2C9%2C72%2C5&w1l=22&w2l=0",
                      'name': 'Star Compass',
                      'difficulty': 'Reincarnation',
                      'author': 'Yaroslohom',
                      'status': 'Ready'
                    },
                      { 
                        page: "/ZiXiao",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                       { 
                        page: "/ZiXiao",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                       'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                      { 
                        page: "/ZiXiao",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                    ],
            },
              {
   name: 'Nona',
   icon: Nona_Icon,
   builds: [
                    {
                       
                       page: "/Nona",
                       parameters: "bl=60%2C63%2C12&sc=1%2C40%2C49%2C50%2C58%2C59%2C66%2C82%2C85%2C105%2C109%2C110%2C113%2C115%2C130%2C147&g=175&g2=175&sk=133333000003000000&w=29%2C39&i=20%2C11%2C57%2C2&i2=6%2C85%2C66%2C54&w1l=22&w2l=4",
                      'name': 'Combustion Mech',
                      'difficulty': 'Reincarnation',
                      'author': 'Yaroslohom',
                      'status': 'Ready'
                    },
                       { 
                        page: "/Nona",
                       parameters: "bl=60%2C22%2C5&sc=32%2C36%2C47%2C77%2C82%2C95%2C100%2C108%2C110%2C113%2C114%2C124%2C126%2C127%2C136%2C142%2C148&sk=000020030303033300&w=21%2C1&g2=172&i2=9%2C3%2C73%2C13&g=172&i=16%2C42%2C71%2C45",
                      'name': 'Hardwood Missile (Mech) with Pistol',
                      'difficulty': 'Reincarnation',
                      'author': 'Yaroslohom',
                      'status': 'Ready'
                    },
                      { 
                        page: "/Nona",
                       parameters: "bl=61%2C5%2C3&sc=1%2C10%2C40%2C48%2C58%2C59%2C60%2C85%2C100%2C103%2C109%2C110%2C115%2C130%2C147&sk=003000133333000000&w1l=0&w2l=0&w=54%2C16&g=176&i=4%2C40%2C57%2C8&g2=176",
                      'name': 'Hardwood Missile',
                      'difficulty': 'Reincarnation',
                      'author': 'Yaroslohom',
                      'status': 'Ready'
                    },
                       { 
                        page: "/Nona",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                       'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                      { 
                        page: "/Nona",
                       parameters: "bl=29%2C3%2C19&g=175&g2=175&sc=10%2C48%2C49%2C50%2C58%2C59%2C60%2C100%2C109%2C110%2C115%2C130%2C147%2C150%2C157&sk=003000000000333031&i=8%2C62%2C64%2C76&w1l=5&w2l=5&i2=3%2C16%2C47%2C53&w=41%2C41",
                      'name': 'Atomic Bomb Build',
                      'difficulty': 'Reincarnation',
                      'author': 'Nameless Hero',
                    },
                    ],
            },
];

export default buildPresets;